import { createSlice } from "@reduxjs/toolkit";
import { signInWithEmailPassword, signOut } from "../actions/auth.actions";
const initialState = {
  user: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
    },
    updateUserAfterPayment: (state, action) => {
      // state.user = {
      //   ...state.user,
      //   ...state.user.userInfo,
      //   profile_expiry_date: action.payload.data.data.profile_expiry_date,
      //     is_paid_customer: action.payload.data.data.is_paid_customer,
      //     paid_customer_type: action.payload.data.data.paid_customer_type,
      //   // Assuming the action payload contains the information to update the user
      //   // ...action.payload,
      // };
      if (state.user && state.user.userInfo) {
        state.user.userInfo = {
          ...state.user.userInfo,
          profile_expiry_date: action.payload.data.data.profile_expiry_date,
          is_paid_customer: action.payload.data.data.is_paid_customer,
          paid_customer_type: action.payload.data.data.paid_customer_type,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInWithEmailPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signInWithEmailPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(signInWithEmailPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(signOut.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.user = null;
      })
      .addCase(signOut.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectUser = (state) => state.auth.user;
export const { resetState, updateUserAfterPayment } = authSlice.actions;
export default authSlice.reducer;
