import React, { Fragment, useState } from "react";
import { Layout, Breadcrumb } from "antd";
import { Outlet } from "react-router-dom";
import Navbar from "../components/ReusableComponents/Navbar/Navbar";
import Sidebar from "../components/ReusableComponents/Sidebar/Sidebar";
import "./MainLayout.css";

const { Sider, Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="main-layout">
      <Navbar />
      <div className="main-content-layout">
        <Layout style={{ minHeight: "100vh" }}>
          <Sider collapsible collapsed={collapsed} onCollapse={toggleSider}>
            <Sidebar collapsed={collapsed} onCollapse={toggleSider} />
          </Sider>

          <Layout>
            <Content style={{ margin: "16px" }}>
              <div className="page-content">
                <Outlet />
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </div>
  );
};

export default MainLayout;
