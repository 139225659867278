import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import {
  UserOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  HomeOutlined,
  FileTextOutlined,
  LoginOutlined,
  UserAddOutlined,
  CheckCircleOutlined,
  FileSearchOutlined,
  AuditOutlined,
  CarryOutOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../../redux/actions/auth.actions";

import logo from "../../../assets/logo/logo.svg";
import "./navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState("");
  const [userID, setUserId] = useState("");
  const [grandUserID, setgrandUserId] = useState("");
  const [userIcon, setUserIcon] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const currentUser = useSelector((state) => state.auth?.user?.userInfo);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const userName = localStorage.getItem("userName");
    const userId = localStorage.getItem("userId");
    const grandUser = localStorage.getItem("grantUser");

    if (userName) {
      const digits = userName.slice(0, 2);
      setUserIcon(digits);
    }
    setUserData(userName);
    setUserId(userId);
    setgrandUserId(grandUser);
    setLoading(false); // Mark loading as complete
  };

  const handleLogout = async () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("grantUser");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    localStorage.removeItem("uuid");
    await dispatch(signOut());
    navigate("/signin");
  };

  const home = () => {
    navigate("/");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const menuClickHandler = (e) => {
    // Handle menu item clicks here
    switch (e.key) {
      case "profile":
        navigate("/profile");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
  };

  const userMenu = (
    <Menu onClick={menuClickHandler}>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        Profile Update
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        Settings
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="header-navbar">
      <Link to={currentUser?.user_id ? "/dashboard" : "/"}>
        <img src={logo} alt="logo" />
      </Link>

      {loading ? (
        <></>
      ) : (
        <div className="navbar">
          <ul id="navbar">
            {userData ? (
              <>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>

                <li>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </li>

                <li>
                  <a
                    href={`https://gtalentpro.com/signin?token=${grandUserID}`}
                    target="_blank"
                  >
                    {" "}
                    Jobs{" "}
                  </a>
                </li>

                <li>
                  <a href={`https://gtalentpro.com/companies`} target="_blank">
                    {" "}
                    Companies{" "}
                  </a>
                </li>

                <li>
                  <a href={`https://gtalentpro.com/events`} target="_blank">
                    {" "}
                    Events{" "}
                  </a>
                </li>

                <div className="navbar-right">
                  <Dropdown overlay={userMenu}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      style={{ textDecoration: "none" }}
                    >
                      <span className="firstLetter"> {userIcon}</span>
                    </a>
                  </Dropdown>
                </div>
              </>
            ) : (
              <>
                <li>
                  <a href="#">Home</a>
                </li>

                <li>
                  <a href="#"> FAQs</a>
                </li>
                <li>
                  <NavLink to="/signin" exact className="active">
                    {" "}
                    Login{" "}
                  </NavLink>
                </li>
                <li>
                  <a href="https://gtalentpro.com/register">Register</a>
                </li>
              </>
            )}
          </ul>
        </div>
      )}

      <div id="mobile">
        <div
          id="menu-icon"
          className={menuOpen ? "close-icon" : "bars-icon"}
          onClick={toggleMenu}
        >
          {menuOpen ? <IoMdClose /> : <FaBars />}
        </div>
      </div>

      {menuOpen && !loading && (
        <div id="mobile-menu">
          {userData ? (
            <ul>
            
              <li>
                <a href="/dashboard">
                  <HomeOutlined /> Dashboard
                </a>
              </li>

              <li>
                <Link to="/profile">
                  <UserOutlined /> Profile Update
                </Link>
              </li>

              <li>
                <a href="/resume-templates">
                  <FileTextOutlined /> Resume Templates
                </a>
              </li>
              <hr style={{color:'#fff'}}/>
              <li> 
                <a href="https://gtalentpro.com/jobs" translate="_blank">
                <FileSearchOutlined /> Jobs
                </a>
              </li>

              <li> 
                <a href="/jobs-applied">
                  <UserOutlined /> Applied Jobs
                </a>
              </li>
              <li>
                <a href="/referrals">
                  <QuestionCircleOutlined /> Referrals
                </a>
              </li>
              <hr style={{color:'#fff'}}/>
              <li>
                {" "}
                <a href="https://gtalentpro.com/companies" translate="_blank">
                <AuditOutlined /> Companies
                </a>
              </li>

              <li> 
              <a href="https://gtalentpro.com/events" translate="_blank">
              <CarryOutOutlined /> Events
                </a>
              </li>
              <hr style={{color:'#fff'}}/>
              <li>
                <a href="/support">
                  <CheckCircleOutlined /> Support
                </a>
              </li>

              <li>
                <a href="/settings">
                  <SettingOutlined /> Settings
                </a>
              </li>

              <li>
                <a href="#" onClick={handleLogout}>
                  <LogoutOutlined /> Logout
                </a>
              </li>
              <li>
                <a href="/">
                  <HomeOutlined /> Home
                </a>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <a href="#">
                  <FileTextOutlined /> Resume Templates
                </a>
              </li>
              <li>
                <a href="#">
                  <QuestionCircleOutlined /> FAQs
                </a>
              </li>
              <li>
                {" "}
                <Link to="/signin">
                  <LoginOutlined /> Login
                </Link>{" "}
              </li>
              <li>
                <a href="https://gtalentpro.com/register">
                  <UserAddOutlined /> Register
                </a>
              </li>
            </ul>
          )}
        </div>
      )}
    </nav>
  );
}

export default Navbar;
