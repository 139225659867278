import React, { useEffect, useState } from "react";
import { Button, Progress, Modal, Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { getResumeTempaltes } from "../../redux/actions/dashboard.action";

function ViewTemplates({ userId, userDatas }) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  // const [templates, setTemplates] = useState([]);
  const currentUser = useSelector((state) => state.auth?.user?.userInfo);
  const templates = useSelector((state) => state.dashboard?.resumeTemplates);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
    setPreviewVisible(true);
  };

  const handleCancelPreview = () => {
    setPreviewVisible(false);
  };

  const selectResumeTemplate = async (id, template) => {
    try {
      const result = await Swal.fire({
        title: "Resume Template",
        text: "Are you sure you want to select this template?",
        // icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#233d7b",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, submit it!",
      });

      if (result.isConfirmed) {
        const data = {
          id: id,
          resume_template: template,
        };
        const response = await axios.post(
          `${BASE_URL}/dashboard/templates`,
          data
        );

        if (response.data.status === true) {
          navigate("/profile");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const paidResumeTemplate = async () => [navigate("/pricing")];

  useEffect(() => {
    // if(templates && templates.length == 0 ){
    //   getAllTemplates();
    // }else{
    //   console.log("else part")
    // }
    getAllTemplates();
  }, []);

  const getAllTemplates = async () => {
    await dispatch(getResumeTempaltes());
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {Array.isArray(templates) &&
          [...templates]
            .sort((a, b) =>
              userDatas.resume_template === a.id
                ? -1
                : userDatas.resume_template === b.id
                ? 1
                : 0
            )
            .map((temp) => (
              <Col
                key={temp.id}
                className="ss1"
                xs={24}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Card
                  className="card"
                  style={{
                    border:
                      userDatas.resume_template === temp.id
                        ? "2px solid #3645a1"
                        : "none",
                  }}
                >
                  <div
                    className="card-body ss-body"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <img
                      className="ss1-img"
                      src={temp.image}
                      alt="Profile"
                      onClick={() => handlePreview(temp.image)}
                    />
                    <h4 className="ss-h4">{temp.title}</h4>
                    {currentUser?.is_paid_customer === 1 ? (
                      <Button
                        type="primary"
                        className="button-custom"
                        style={{
                          backgroundColor:
                            userDatas.resume_template === temp.id
                              ? "#3ab380"
                              : "#233d7b",
                          color: "white",
                          display: "inline-block",
                        }}
                        onClick={() => selectResumeTemplate(userId, temp.id)}
                      >
                        Use Template
                      </Button>
                    ) : temp.subscription === "Paid" &&
                      currentUser?.is_paid_customer === 0 ? (
                      <Button
                        type="primary"
                        className="button-custom"
                        style={{
                          backgroundColor:
                            userDatas.resume_template === temp.id
                              ? "#3ab380"
                              : "#233d7b",
                          color: "white",
                          display: "inline-block",
                        }}
                        onClick={() => paidResumeTemplate(userId, temp.id)}
                      >
                        Upgrade Now
                      </Button>
                    ) : temp.subscription === "Free" ||
                      currentUser?.is_paid_customer === 1 ? (
                      <Button
                        type="primary"
                        className="button-custom"
                        style={{
                          backgroundColor:
                            userDatas.resume_template === temp.id
                              ? "#3ab380"
                              : "#233d7b",
                          color: "white",
                          display: "inline-block",
                        }}
                        onClick={() => selectResumeTemplate(userId, temp.id)}
                      >
                        {userDatas.resume_template === temp.id
                          ? "Selected Template"
                          : temp.subscription === "Free"
                          ? "Free Template"
                          : "Paid Template"}
                      </Button>
                    ) : null}
                  </div>
                </Card>
              </Col>
            ))}
      </Row>

      <Modal
        visible={previewVisible}
        onCancel={handleCancelPreview}
        footer={null}
      >
        <img
          alt="template"
          src={previewImage}
          style={{ width: "100%", marginTop: "25px" }}
        />
      </Modal>
    </>
  );
}

export default ViewTemplates;
