import React, { Fragment } from "react";
import ViewTemplates from "../../../components/Dashboard/viewTemplates";
import { useDispatch, useSelector } from "react-redux";
import "../../../components/Dashboard/styles.css";
const ResumeTemplatesMain = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth?.user?.userInfo);
  return (
    <Fragment>
      <h1
        className="home-head"
        style={{ marginBottom: "25px", marginTop: "40px", textAlign: "center" }}
      >
        Professional Resume Template
      </h1>
      <ViewTemplates userId={currentUser?.user_id} userDatas={currentUser} />
    </Fragment>
  );
};
export default ResumeTemplatesMain;
