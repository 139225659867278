import { createSlice } from "@reduxjs/toolkit";
import { getResumeTempaltes } from "../actions/dashboard.action";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    resumeTemplates: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetDashboardState: (state) => {
      return {
        resumeTemplates: [],
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResumeTempaltes.pending, (state, action) => {
        state.loading = true;
        state.resumeTemplates = action.payload;
      })
      .addCase(getResumeTempaltes.fulfilled, (state, action) => {
        state.loading = false;
        state.resumeTemplates = action.payload?.data;
        state.error = "";
      })
      .addCase(getResumeTempaltes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetDashboardState } = dashboardSlice.actions;
export default dashboardSlice.reducer;
