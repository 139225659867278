import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../utils/helper";
import api from "../../utils/api";

export const getResumeTempaltes = createAsyncThunk(
  "Resume/templates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/templates`);
      return response.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

export const isCheckPrimium = createAsyncThunk(
    "Pdf/checkPrimium",
    async (userId, { rejectWithValue }) => {
      try {
        const response = await api.post(`${BASE_URL}/profileupdate/pdf/primium`, {userId: userId});
        return response.data;
      } catch (error) {
        console.error("Email login error:", error);
        throw rejectWithValue(error);
      }
    }
  );

export const downloadPDFAction = createAsyncThunk(
    'profile/downloadPdf',
    async ({ userId, uid }, { rejectWithValue }) => {
      try {
        const response = await api.get(`/profileupdate/pdf/${userId}`, {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/pdf',
            },
          });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${uid}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        return { success: true };
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
  );