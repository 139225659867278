import React from "react";
import { Navigate, Outlet } from "react-router";

export const ProtectedRouteLayout = () => {
  const userId = localStorage.getItem("userId");

  if (!userId) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};
