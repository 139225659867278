import React from "react";
import { Menu } from "antd";
import {
  FileDoneOutlined,
  LaptopOutlined,
  SettingOutlined,
  LogoutOutlined,
  DollarOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../../redux/actions/auth.actions";

function Sidebar({ collapsed, onCollapse }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("grantUser");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    localStorage.removeItem("uuid");
    await dispatch(signOut());
    navigate("/signin");
  };

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const items = [
    getItem("Resume", "sub", <FileDoneOutlined />, [
      getItem("Dashboard", "dashboard"),
      getItem("Update Resume", "profile"),
      getItem("Templates", "resume-templates"),
    ]),
    getItem("Jobs", "sub", <LaptopOutlined />, [
      getItem("Applied Job List", "jobs-applied"),
      getItem("Referrals", "referrals"),
    ]), 
    getItem("Transaction", "sub", <DollarOutlined />, [
      getItem("Payment History", "payment-history"),
      getItem("Pricing", "pricing"), 
    ]), 
    getItem("Settings", "settings", <SettingOutlined />),
    getItem("Support", "support", <QuestionCircleOutlined />),
    getItem("Logout", "logout", <LogoutOutlined onClick={handleLogout} />),
  ];

  const renderMenuItems = (menuItems) => {
    return menuItems.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            icon={item.icon}
            title={item.label}
            openKeys={[item.key]}
          >
            {renderMenuItems(item.children)}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link
              to={`/${item.key}`}
              onClick={item.key === "logout" ? handleLogout : null}
              style={{textDecoration: 'none', fontFamily:'Poppins'}}
            >
              {item.label}
            </Link>
          </Menu.Item>
        );
      }
    });
  };

  // Get the key of the currently active route
  const activeRouteKey = location.pathname.split("/")[1];

  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[activeRouteKey]}
      defaultOpenKeys={["sub"]}
      mode="inline"
      inlineCollapsed={collapsed}
      onCollapse={onCollapse}
    >
      {renderMenuItems(items)}
    </Menu>
  );
}

export default Sidebar;
