import React, { Fragment, useState } from "react";
import { Layout, Breadcrumb } from "antd";
import PaymentList from "../../components/Transactions/paymentList";

const PaymentListMain = () => {
  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0", padding: "10px" }}>
        <Breadcrumb.Item>Payment</Breadcrumb.Item>
        <Breadcrumb.Item>List</Breadcrumb.Item>
      </Breadcrumb>
      <PaymentList />
    </Fragment>
  );
};
export default PaymentListMain;
