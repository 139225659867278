import React, { useState, useEffect } from "react";
import { Table, Tag, Spin } from "antd";
import moment from "moment";
import { getPaymentList } from "../../redux/actions/paytment.action";
import { useSelector, useDispatch } from "react-redux";
const { Column } = Table;

const PaymentList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth?.user?.userInfo);
  const list = useSelector((state) => state.pricingPlan?.paymentList);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getPaymentList(currentUser?.user_id));
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Spin spinning={loading}>
        {/* <JobInformation /> */}
        <Table dataSource={list}>
          <Column
            title="S.No"
            dataIndex="id"
            key="id"
            render={(text, record, index) => index + 1}
          />

          <Column title="Title" dataIndex="title" key="title" responsive={['xs','md']}/>

          <Column title="Total Amount" dataIndex="amount" key="amount" responsive={['xs', 'md']}/>

          <Column
            title="Status"
            dataIndex="order_status"
            key="order_status"
            render={(order_status) => (
              <Tag color={order_status == "Paid" ? "success" : "red"}>
                <i
                  className={
                    order_status == "Paid"
                      ? "fa fa-circle text-success me-1"
                      : "fa fa-circle text-danger me-1"
                  }
                ></i>
                {order_status ? order_status : "Applied"}
              </Tag>
            )}
          />

          <Column
            title="Payment Method"
            dataIndex="payment_method"
            key="payment_method"
            responsive={['md']}
          />

          <Column
            title="Payment Type"
            dataIndex="payment_type"
            key="payment_type"
            responsive={['md']}
          />

          <Column
            title="Order Date"
            dataIndex="created_date"
            key="created_date"
            render={(created_date) =>
              moment(created_date).format("DD-MMM-YYYY HH:mm:ss").toLowerCase()
            }
            responsive={['md']}
          />

          <Column
            title="Subscription Validity"
            dataIndex="profile_expiry_date"
            key="profile_expiry_date"
            render={(profile_expiry_date) =>
              moment(profile_expiry_date).format("DD-MMM-YYYY").toLowerCase()
            }
            responsive={['md']}
          />

          <Column title="Order Id" dataIndex="order_id" key="order_id" responsive={['md']}/>
          <Column title="Payment Id" dataIndex="payment_id" key="payment_id" responsive={['md']} />
        </Table>
      </Spin>
    </>
  );
};
export default PaymentList;
