import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../utils/helper";
import Interceptor from "../../utils/services/interceptor";
import api from "../../utils/api";
import { updateUserAfterPayment } from "../reducers/auth.reducer";

export const getPricingPlan = createAsyncThunk(
  "payment/plan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/payment/plan`);
      return response.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

export const getPaymentList = createAsyncThunk(
  "payment/list",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.get(`${BASE_URL}/payment/list/${userId}`);
      return response?.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

export const createOrders = createAsyncThunk(
  "payment/createOrder",
  async ({ amount, userId, discount, coupon, planId, subtotal, gstAmount, total }, { rejectWithValue }) => {
    try {
      
      const response = await api.post(`${BASE_URL}/payment/create-order`, {
        amount: amount,
        userId,
        discount,
        coupon,
        planId,
        subtotal, 
        gstAmount, 
        total
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyPayment = createAsyncThunk(
  "payment/verifyPayment",
  async (paymentData, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `${BASE_URL}/payment/verify-payment`,
        paymentData
      );
      await dispatch(updateUserAfterPayment(response));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkCouponCode = createAsyncThunk(
  "payment/checkCouponCode",
  async (data, { rejectWithValue }) => {
    try {
      const coupon = { couponCode: data };
      const response = await api.post(
        `${BASE_URL}/payment/check-coupon`,
        coupon
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
